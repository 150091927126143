import React from "react"
import {
  Heading,
  Section,
  Link,
  Image,
  BadgeISO,
  BadgeSAP,
  BadgeDNV,
  Button,
  BadgeAward,
  BackgroundImage,
} from "components"
import { useStaticQuery, graphql } from "gatsby"
import { FaCheckCircle } from "react-icons/fa"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      clients: allFile(filter: { relativePath: { regex: "/clients/" } }) {
        edges {
          node {
            asset: childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      file(relativePath: { eq: "bondmann.jpg" }) {
        asset: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      team: file(relativePath: { eq: "todos-ok.jpg" }) {
        asset: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      infografico: file(relativePath: { eq: "infografico.png" }) {
        asset: childImageSharp {
          fluid(quality: 90, maxWidth: 1600, maxHeight: 1132) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mapa: file(relativePath: { eq: "mapa.png" }) {
        asset: childImageSharp {
          fluid(quality: 96) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      total: file(relativePath: { eq: "total-lubrificantes.png" }) {
        asset: childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      totalBoxes: file(
        relativePath: { eq: "bondmann-total-lubrificantes-graxas.png" }
      ) {
        asset: childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      identidade: file(relativePath: { eq: "identidade-corporativa.png" }) {
        asset: childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      team: file(relativePath: { eq: "todos-ok.jpg" }) {
        asset: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sede: file(relativePath: { eq: "bondmann-sede-sul.png" }) {
        asset: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allSanityDepartment {
        edges {
          node {
            title
            slug {
              current
            }
            description
            image {
              asset {
                fluid(maxWidth: 1600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Section>
        <div className="grid gap-16 lg:grid-cols-2">
          <div>
            <Heading>Apresentação</Heading>

            <p>
              Tudo ao nosso redor envolve química. A nossa própria vida é um
              fantástico processo químico. A química tem garantido que o ser
              humano tenha uma vida mais longa e confortável, através do
              desenvolvimento de soluções para os mais variados problemas. Ela
              também explica os fenômenos que nos rodeiam e ajuda-nos a entender
              como a atividade humana tem se desenvolvido ao longo dos anos.
            </p>
            <p>
              Para permitir que esses avanços aconteçam de forma sistemática e
              constante, e para que se produza sempre inovação, é que existe a
              indústria química. E é neste cenário que surge, há mais de 30
              anos, a BONDMANN. Uma provedora de produtos e serviços na área
              química voltada à manutenção e produtividade industrial.
              Reconhecida pela qualidade técnica de seus processos, pela
              proximidade com o mercado, inovação tecnológica e
              sustentabilidade.
            </p>
            <p>
              Atuando de forma parceira com seus clientes, a BONDMANN gera a
              segurança de uma entrega confiável, garantindo fidelidade de
              clientes e fornecedores, nessas mais de 3 décadas de atuação.
            </p>
            <p>
              A BONDMANN é reconhecida no mercado nacional pela sua eficiência,
              o que confere a seus clientes um fornecimento de soluções químicas
              para que eles evoluam em seus mercados, com produtividade e
              inovação.
            </p>
          </div>

          <div className="relative mb-6">
            <Image
              className="w-full h-full border-b-4 rounded-sm aspect-ratio-16/9 shadow-soft border-accent-500"
              source={data.sede}
            />
          </div>
        </div>
      </Section>

      <Section className="py-32" background="bg-primary-800">
        <div className="grid gap-16 text-white lg:grid-cols-3">
          <div>
            <Heading level="2" className="text-accent-500">
              Negócio
            </Heading>

            <p className="text-xl">
              Facilitar e qualificar processos, manutenções e higienizações com
              máxima efetividade e sustentabilidade.
            </p>
          </div>

          <div>
            <Heading level="2" className="text-accent-500">
              Missão
            </Heading>

            <p className="text-xl">
              Promover a competitividade na indústria brasileira com agilidade,
              orientações e suporte a partir de soluções químicas de eficiência
              e qualidade.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="mb-6">
          <Image
            className="w-full border-b-4 rounded-sm shadow-soft border-accent-500"
            source={data.infografico}
          />
        </div>
      </Section>

      <Section background="bg-gray-100">
        <div className="max-w-lg">
          <Heading level="3" className="text-accent-500">
            Atuação
          </Heading>
          <p>
            A BONDMANN atua a partir de seis sub-marcas que definem claramente o
            escopo de seus produtos, todos eles cumprindo o mais alto rigor
            técnico, com uma série de documentos que corroboram a qualidade e
            tranquilizam os clientes para uma operação segura e eficiente.
          </p>
        </div>

        <div className="grid col-span-2 gap-8 mt-16 md:grid-cols-1 lg:grid-cols-2 grid-equal-height">
          {data.allSanityDepartment.edges.map(
            ({ node: { title, slug, description, image } }) => (
              <div className="relative overflow-hidden transition transform rounded-sm hover:-translate-y-1 shadow-soft">
                <Link to={`/${slug.current}`}>
                  <div className={`p-8 relative z-20 text-white w-3/5`}>
                    <Heading
                      level="4"
                      className="text-xl font-medium text-white"
                    >
                      {title}
                    </Heading>
                    <div>{description}</div>
                  </div>

                  {image && <BackgroundImage source={image} />}
                </Link>
              </div>
            )
          )}
        </div>
      </Section>

      <Section>
        <Heading level="2">Alguns dos nossos mais de 4 mil clientes</Heading>

        <div className="grid items-center grid-cols-3 gap-12 py-16 md:grid-cols-6 lg:grid-cols-9">
          {data.clients.edges.map(({ node }) => {
            return (
              <div>
                <div>
                  <Image className="w-full" source={node} />
                </div>
              </div>
            )
          })}
        </div>
      </Section>

      <Section>
        <div className="grid items-center gap-16 lg:grid-cols-2">
          <div>
            <Heading>Abrangência</Heading>

            <p>
              Possuímos uma série de documentos que corroboram nossa qualidade e
              tranquilizam clientes para uma operação segura e eficiente.{" "}
            </p>
            <p>
              Contamos com um departamento químico atuante, sanando dúvidas,
              desenvolvendo novas soluções e criando facilidades e qualidades
              para os mais variados processos. Não á toa, inovamos
              constantemente no mercado e conquistamos uma posição de referência
              dentro de cada cliente em nossa área de expertise.
            </p>
          </div>

          <div className="mb-6 ">
            <Image className="w-full h-full rounded-sm" source={data.mapa} />
          </div>
        </div>
      </Section>

      {/* <Section background="bg-gray-100">
        <div className="grid gap-16 md:grid-cols-2">
          <div className="space-y-8">
            <Kicker>Total Lubrificantes</Kicker>
            <Heading>
              A Bondmann Química. Distribuidora dos lubrificantes industriais da
              Total.
            </Heading>
          </div>

          <div></div>

          <div>
            <div className="relative mb-6">
              <Image
                className="w-full h-full border-b-4 rounded-sm shadow-soft border-accent-500"
                source={data.total}
              />
            </div>

            <div className="relative mb-6">
              <Image
                className="w-full h-full px-8 border-b-4 rounded-sm shadow-soft border-accent-500"
                source={data.totalBoxes}
              />
            </div>
          </div>

          <div className="space-y-8">
            <Heading level="4">Total no Mundo</Heading>

            <p>
              A Total é a 4ª maior empresa internacional de petróleo e gás. Suas
              atividades abrangem exploração e produção de petróleo e gás,
              refinação, petroquímica e comercialização de combustíveis e
              lubrificantes.
            </p>

            <Heading level="4">No Brasil</Heading>
            <p>
              No país, a Total conta com uma estrutura aliada aos padrões
              internacionais de tecnologia e de qualidade da marca, com uma
              moderna fábrica em Pindamonhangaba e uma central de atendimento
              com capacidade para orientar, atender e informar sobre a linha
              completa de produtos que a empresa comercializa no país.
            </p>
            <p>
              Desde 2019, a Bondmann Química é distribuidora dos lubrificantes e
              graxas industriais da Total.
            </p>
            <p>
              A Total é a 4ª maior empresa internacional de petróleo e gás. Suas
              atividades abrangem exploração e produção de petróleo e gás,
              refinação, petroquímica e comercialização de combustíveis e
              lubrificantes.
            </p>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="grid gap-16 lg:grid-cols-2">
          <div>
            <Heading>Qualidade e inovação</Heading>

            <p>
              A Bondmann está comprometida com a melhoria contínua do Sistema
              Integrado de Gestão, dos seus processos e produtos para a
              satisfação dos clientes, respeito ao meio ambiente, prevenção da
              poluição, atendimento aos requisitos legais e estatutários,
              através do trabalho em equipe e capacitação dos colaboradores em
              função do CHA (Conhecimento, Habilidade e Atitude).
            </p>
            <p>
              Buscamos um diferencial competitivo para sermos reconhecidos como
              uma marca digna de confiança, comprometidos em satisfazer os
              requisitos dos clientes, legais e de sistemas de gestão da
              qualidade e ambiental, aplicáveis para a obtenção de resultados
              que beneficiem a todos.
            </p>
          </div>

          <div className="grid items-center justify-center grid-cols-2 gap-16 py-8 text-primary-500">
            <div className="flex justify-center">
              <BadgeAward className="w-56" />
            </div>
            <div className="flex justify-center">
              <BadgeISO className="w-40" />
            </div>
            <div className="flex justify-center">
              <BadgeSAP className="w-40" />
            </div>
          </div>
        </div>
      </Section>

      <Section background="bg-gray-100 border-t-4 border-accent-500">
        <div className="grid items-center gap-16 lg:grid-cols-2">
          <div>
            <Heading>Rigor técnico</Heading>

            <p>
              Possuímos uma série de documentos que corroboram nossa qualidade e
              tranquilizam clientes para uma operação segura e eficiente.
              Contamos com um departamento químico atuante, sanando dúvidas,
              desenvolvendo novas soluções e criando facilidades e qualidades
              para os mais variados processos. Não á toa, inovamos
              constantemente no mercado e conquistamos uma posição de referência
              dentro de cada cliente em nossa área de expertise.
            </p>

            <Button isPrimary to="/contato">
              Solicitar documentação
            </Button>
          </div>

          <div>
            <ul className="text-2xl italic list-none lg:text-3xl text-primary-500 font-display">
              <li className="flex flex-wrap items-center space-x-4">
                <FaCheckCircle className="text-accent-500" />{" "}
                <span>Laudos analíticos de laboratórios externos</span>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheckCircle className="text-accent-500" />
                <span> Relatórios técnicos de testes de produtos</span>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheckCircle className="text-accent-500" />
                <span> Fispq de produtos</span>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheckCircle className="text-accent-500" />
                <span> Fichas descritivas de produtos</span>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </>
  )
}

export default AboutPage
